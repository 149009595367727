import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function FileUpload() {
    const [file, setFile] = useState(null);
    const navigate = useNavigate();

    // Maneja el cambio de archivo en el input
    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    // Envía el archivo y redirige a la pantalla de edición
    const handleSubmit = (e) => {
        e.preventDefault();
        if (file) {
            console.log('Archivo subido:', file.name);
            // Simular una subida del archivo aquí si es necesario
            // fetch('your-backend-endpoint', { method: 'POST', body: formData });
            
            // Redirigir a la página de edición y pasar el archivo como estado
            navigate('/contract/edit-pdf', { state: { file } });
        } else {
            console.log('No se ha seleccionado ningún archivo.');
        }
    };

    return (
        <div>
            <h1>Subir Contrato Digital</h1>
            <form onSubmit={handleSubmit}>
                <input
                    type="file"
                    onChange={handleFileChange}
                    accept="application/pdf"
                />
                <button type="submit">Subir documento</button>
            </form>
        </div>
    );
}
