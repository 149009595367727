import React from 'react';
import SecondaryHeader from './secondary-header';
import Builder from './builder';
import Footer from '../landingPage/Footer';

function BuilderPage() {
  return (
    <div>
        <SecondaryHeader />
        <Builder />
        <Footer />
    </div>
  );
}

export default BuilderPage;
