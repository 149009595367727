import React from 'react';
import { Link } from 'react-router-dom';
import logo from "../../assets/logos/logo_blanco.svg"

const Footer = () => {
    return (
        <footer className="text-white pt-5">
            <div className="container-fluid px-4 pb-5">
                <div className="row">
                    <div className="col-md-6">
                    <Link className="navbar-brand" to="/">
                        <img src={logo} alt="Logo" style={{maxHeight: '60px'}}/>
                    </Link>
                        <p>Crea y gestiona contratos con IA.</p>
                    </div>
                    <div className="col-md-6">
                        <h5>Enlaces</h5>
                        <ul className="list-unstyled">
                            <li><a href="/" className="text-white">Inicio</a></li>
                            <li><a href="/crlist" className="text-white">Contratos</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
