import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function ContractRecommendation ({title, desc}) {
    const navigate = useNavigate();

    const handleClick = () =>{
        navigate("/builder");
    }

  return (
    <div className="card" style={{ width: '450px', borderRadius:'25px', backgroundColor:'var(--blanco)'}}>
      <div className="card-body p-4">
        <h5 className="card-title">{title}</h5>
        <h6 className="card-subtitle my-2 text-muted">Te sirve para:</h6>
        <ul className="card-text pb-4">
          {desc.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <button onClick={handleClick} className="btn btn-primary" style={{borderRadius: '15px', backgroundColor: '#2658f5', color:'var(--blanco)', minWidth:'150px',marginRight:'0.5rem' }}>Comenzar</button>
      </div>
    </div>
  );
}
