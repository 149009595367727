import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Menu from './Menu';

export default function EditPdf() {
    const location = useLocation();
    const file = location.state?.file; // Recibe el archivo desde el estado de la ruta
    const fileURL = file ? URL.createObjectURL(file) : null;

    const [droppedFields, setDroppedFields] = useState([]);

    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'FIELD',
        drop: (item, monitor) => {
            const offset = monitor.getClientOffset();
            const newField = {
                type: item.fieldType.id,
                left: offset.x,
                top: offset.y,
            };
            setDroppedFields((prevFields) => [...prevFields, newField]);
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }));

    // Limpieza de la URL cuando el componente se desmonte
    useEffect(() => {
        return () => {
            if (fileURL) {
                URL.revokeObjectURL(fileURL);
            }
        };
    }, [fileURL]);

    return (
        <DndProvider backend={HTML5Backend}>
            <div style={{ display: 'flex' }}>
                <Menu />
                <div 
                    ref={drop} 
                    style={{ 
                        position: 'relative', 
                        width: '80%', 
                        height: '600px', 
                        backgroundColor: isOver ? '#f0f0f0' : 'white' 
                    }}
                >
                    {fileURL ? (
                        <img
                            src={fileURL}
                            alt="PDF"
                            style={{ width: '100%', height: '100%' }} 
                        />
                    ) : (
                        <p>No se ha subido ningún archivo.</p>
                    )}
                    {droppedFields.map((field, index) => (
                        <div
                            key={index}
                            style={{
                                position: 'absolute',
                                left: field.left,
                                top: field.top,
                                background: 'rgba(0,0,0,0.2)',
                                padding: '4px',
                                borderRadius: '4px',
                            }}
                        >
                            {field.type === 'name' && 'Nombre'}
                            {field.type === 'signature' && 'Firma'}
                            {field.type === 'text' && 'Texto'}
                        </div>
                    ))}
                </div>
            </div>
        </DndProvider>
    );
}
