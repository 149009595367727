import React from 'react';
import './content.css'

const Content = () => {
    return (
        // <div className="content-section text-white p-4 py-5 rounded-lg">
        //     <h2 className="content-title">Obtén un contrato en 3 simples pasos</h2>
        //     <p className="content-description">Esta es una breve descripción del contenido. Explora cómo nuestra IA puede ayudarte a crear contratos personalizados en minutos.</p>
        //     <Link to="/destination" className="btn btn-primary">Explorar</Link>
        // </div>
        <div className='p-4'>
        <section className="container-fluid landing-container">
            <h2 className=" mb-4">Obtén un contrato en 3 simples pasos</h2>
            <div className="row" style={{width:'100%'}}>
            <div className="col-md-4">
                <div className="card text-white h-100" style={{borderRadius:'20px', backgroundColor:'#151515'}}>
                    <div className="card-header number-header card-no-border" >1</div>
                    <div className="card-body" style={{paddingTop:'0px', paddingBottom:'2rem'}}>
                        <h5 className="card-title">Define tu necesidad</h5>
                        <p className="card-text">Describe brevemente lo que necesitas en nuestro formulario fácil de usar.</p>
                        {/* <a href="#" className="btn btn-primary">Explorar</a> */}
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="card text-white h-100" style={{borderRadius:'20px', backgroundColor:'#151515'}}>
                    <div className="card-header number-header card-no-border" >2</div>
                    <div className="card-body" style={{paddingTop:'0px'}}>
                        <h5 className="card-title">Selecciona y personaliza</h5>
                        <p className="card-text">Te propondremos un contrato y puedes personalizarlo.</p>
                        {/* <a href="#" className="btn btn-primary">Ver Contratos</a> */}
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="card text-white h-100" style={{borderRadius:'20px', backgroundColor:'#151515'}}>
                    <div className="card-header number-header card-no-border" >3</div>
                    <div className="card-body" style={{paddingTop:'0px'}}>
                        <h5 className="card-title">Revisa y descarga</h5>
                        <p className="card-text">Lee tu contrato generado con nuestra IA y descargalo.</p>
                        {/* <a href="#" className="btn btn-primary">Comenzar</a> */}
                    </div>
                </div>
            </div>
            </div>
        </section>

        
         {/* <section class="container-fluid landing-container my-5">
        <h2 class="mb-4">Nuestra Historia</h2>
        <p>Desde nuestros inicios en 2018, nos hemos dedicado a transformar el acceso a servicios legales mediante la tecnología.</p>
        <img src="nuestra-historia.jpg" class="img-fluid" alt="Nuestra Historia"> 
        </section>

        <section class="container-fluid landing-container my-5">
        <h2 class="mb-4">El Problema Legal</h2>
        <div class="row">
            <div class="col-md-6">
                <ul class="fa-ul">
                    <li><span class="fa-li"><i class="fas fa-balance-scale"></i></span>Complejidad y acceso limitado a la justicia.</li>
                    <li><span class="fa-li"><i class="fas fa-gavel"></i></span>Altos costos de los servicios legales.</li>
                </ul>
            </div>
            <div class="col-md-6">
                 <img src="problema-legal.jpg" class="img-fluid" alt="El Problema Legal"> 
            </div>
        </div>
        </section>  

        <section class="container-fluid landing-container my-5">
            <h2 class=" mb-4">Cómo Innovamos</h2>
            <p class="">A través de nuestra plataforma, implementamos tecnología de punta para simplificar procesos legales.</p>
            <div class="row text-center">
                <div class="col-md-4 mb-4">
                    <i class="fas fa-robot fa-3x"></i>
                    <h5 class="mt-2">Automatización con IA</h5>
                    <p>Utilizamos inteligencia artificial para automatizar la creación y revisión de contratos.</p>
                </div>
                <div class="col-md-4 mb-4">
                    <i class="fas fa-lock fa-3x"></i>
                    <h5 class="mt-2">Seguridad de Datos</h5>
                    <p>Protegemos la información de nuestros usuarios con encriptación avanzada.</p>
                </div>
                <div class="col-md-4 mb-4">
                    <i class="fas fa-users fa-3x"></i>
                    <h5 class="mt-2">Comunidad</h5>
                    <p>Fomentamos una comunidad de expertos legales para compartir conocimientos y experiencias.</p>
                </div>
            </div>
        </section>
*/}
        <section class="container-fluid landing-container my-5">
            <div class="d-flex justify-content-center text-white" style={{height: '35vh'}}>
                <div class="content-secondary d-flex justify-content-center align-items-center p-4" style={{position: 'relative', borderRadius: '25px', backgroundColor: '#151515', width: '100%'}}>
                    <div className='p-3' style={{width: '60%'}}>
                        <h1 className="h2 pb-2">Revolucionamos la creación de contratos</h1>
                        <button 
                            className="btn hero-button position-relative" 
                            onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                            Pruébalo Gratis
                        </button>

                    </div>
                </div>
            </div>
        </section>




        </div>
    );
};

export default Content;
