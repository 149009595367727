import React, { useState, useContext } from 'react';
import Plan from './plan';
import Question from './question';
import Review from './review';
import './builder.css'
import { responseContext } from '../../App';

const Builder = () => {
    const [pasoActual, setPasoActual] = useState(1);
    const [respuestas, setRespuestas] = useState([]);
    const [modoEdicion, setModoEdicion] = useState(false);

    const {response, setResponse} = useContext(responseContext)

    const preguntas = response.preguntas
    const datos = response.datos
    

    // Función para manejar el envío de respuestas
    const handleResponder = respuesta => {
        const nuevasRespuestas = [...respuestas];
        nuevasRespuestas[pasoActual - 2] = respuesta;
        setRespuestas(nuevasRespuestas);
        setResponse({title: response.title, desc: response.desc, datos:response.datos, preguntas:response.preguntas, respuestas:nuevasRespuestas})

        if (modoEdicion) {
            // Si está en modo edición, volver al Review
            setPasoActual(preguntas.length + 2);
            setModoEdicion(false); // Resetear el modo de edición
        } else {
            // Avanza al siguiente paso automáticamente después de responder
            setPasoActual(pasoActual + 1);
        }
    };

    // Permite avanzar directamente si estamos en la fase del plan
    const handleSiguiente = () => {
        setPasoActual(pasoActual + 1);
    };

    // Permite editar una respuesta específica
    const handleEditarRespuesta = (index) => {
        setPasoActual(index + 2); // +2 ajusta para las preguntas
        setModoEdicion(true); // Establecer el modo de edición
    };

    // Determina el componente a renderizar basado en el pasoActual
    const renderizarPaso = () => {
        if (pasoActual === 1) {
            return (
                <div>
                    <Plan 
                        datos={datos} 
                        onSiguiente={handleSiguiente}
                    />
                    {/* <button className="btn btn-primary mt-3" onClick={handleSiguiente}>Siguiente</button> */}
                </div>
            );
        } else if (pasoActual === preguntas.length + 2) {
            return (
                <Review preguntasRespuestas={preguntas.map((pregunta, index) => ({
                    pregunta, respuesta: respuestas[index]}))}
                    onEditar={handleEditarRespuesta}
                />
            );
        } else {
            return (
                <Question
                    pregunta={preguntas[pasoActual - 2]}
                    onResponder={handleResponder}
                />
            );
        }
    };
    

    return (
        <div className="d-flex justify-content-center text-white p-4" style={{height: '90vh'}}>
        <div className="content p-4" style={{position: 'relative', height: '100%', borderRadius: '25px', backgroundColor: '#151515', width: '100%'}}>
            <div className="progress-info" style={{position: 'absolute', top: 0, left: 0, padding: '20px'}}>
            <h5 className='progress-title'>Progreso del Contrato</h5>
            <p className="progress-text">Paso {pasoActual} de {preguntas.length + 2}</p>
            </div>
            <div className="content-body p-5" style={{ minHeight: '100%'}}>
                {renderizarPaso()}
            </div>
        </div>
        </div>


    );
    
};

export default Builder;
