import React, {useState, useContext, useEffect} from "react";
import SecondaryHeader from './secondary-header';
import Footer from '../landingPage/Footer';
import { sendPrompt } from "../../services/ContractAPI";
import './editor.css'
import { responseContext } from "../../App";


export default function Editor() {

    const {response} = useContext(responseContext);

    const datoRespuestas = response.datos.map((dato, index) => {
        return `${dato} Respuesta: ${response.respuestas[index]}`;
      }).join(', ');
    
    console.log(response.datos)
    console.log(response.respuestas)
    console.log(datoRespuestas)

    const [paragraphs, setParagraphs] = useState([]);

    const msg = "Crea un "+response.title+" completo de al menos 500 palabras donde se incluyan con mucho detalle los siguientes datos: "+datoRespuestas+". Si consideras que faltan datos, no los inventes, solo escribelo como [dato]. Regresa únicamente el texto del contrato en formato JSON en un arreglo de parrafos llamado 'parrafos'";
    
    
    useEffect(() => {
        sendPrompt(msg)
            .then(data => {
                const jsonData = JSON.parse(data); // Suponiendo que sendPrompt devuelve una cadena JSON como texto
                if (jsonData && jsonData.parrafos) {
                    setParagraphs(jsonData.parrafos);
                } else {
                    // Manejo de errores o respuestas inesperadas
                    setParagraphs(['Error al crear el contrato, intente de nuevo más tarde.'])
                }
            })
            .catch(error => {
                console.error("Error al obtener los datos:", error);
            });

    }, []);

    return (
        <React.Fragment>
            <SecondaryHeader/>
            <div className="p-4 editing-section">
                <div className="row">
                    <div className="col-md-6 p-5 content-document">
                    <div className="overflow-auto" style={{ maxHeight: "80vh" }}>
                        <h3 className="text-center fw-bold my-3">{response.title}</h3>
                        {paragraphs.map((paragraph, index) => (
                        <p key={index} className="my-2">{paragraph}</p>
                        ))}
                    </div>
                    </div>

                    <div className="col-md-6 d-flex" style={{ minWidth: "300px", paddingRight:'0px', paddingLeft:'1.5rem'}}>
                    <div className="content p-5">
                    <p>Nos esforzamos por asegurar que nuestra Inteligencia Artificial sea lo más precisa posible. Sin embargo, puede cometer errores, motivo por el cual recomendamos una revisión cuidadosa del contrato generado.</p>
                    <p>Para su comodidad, contamos con un equipo de abogados especializados dispuestos a realizar esta verificación de manera detallada y profesional.</p>
                    
                    <div className="d-flex py-4 justify-content-start">
                        <button type="button" className="btn btn-primary mr-3" style={{borderRadius: '15px', backgroundColor: '#2658f5', color:'var(--blanco)', minWidth:'150px', minHeight:'3rem',marginRight:'0.5rem' }}>Solicitar Verificación Legal</button>
                        <button type="button" className="btn btn-secondary" style={{borderRadius: '15px', color: 'var(--blanco)', border: 'none' , minWidth:'150px'}}>Descargar Contrato</button>
                    </div>
                    </div>


                    </div>
                </div>
            </div>
            <Footer/>
        </React.Fragment>
    );
}