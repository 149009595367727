import ContractRecommendation from "./ContractRecommendation"
import React, {useContext, useEffect, useState} from "react"
import { promptContext, responseContext } from "../App";
import { sendPrompt, sendPromptCRList } from "../services/ContractAPI";
import SecondaryHeader from "./ContractBuilder/secondary-header";
import Footer from "./landingPage/Footer";
// import config from "../services/config";
import './landingPage/Hero.css'

export default function CRList () {
    const {prompt} = useContext(promptContext)
    const {response, setResponse} = useContext(responseContext);
    const [loading, setLoading] = useState(true);
    const prod = 1;
    
    useEffect(() => {
        if (prod === 1) {
            const msg = `Este gpt actúa como un asesor legal enfocado en proporcionar recomendaciones concisas y específicas sobre contratos para diversas situaciones legales presentadas por el usuario. Cuando recibe una descripción de un problema legal o una situación que requiere un contrato, el GPT responde generando un JSON que contiene un objeto 'data'. Dentro de 'data', se incluye un título de contrato recomendado ('title') y una lista de tres puntos clave ('desc'), describiendo para qué sirve el contrato, con cada punto siendo de tres a cinco palabras. Además, una lista de datos ('datos') requeridos por el usuario para llenar un contrato COMPLETO de ese tipo, alrededor de 10 a 20, pueden ser más o menos si es necesario. Por último, regresa al mismo tiempo una pregunta ('preguntas') por cada 'dato' que hayas regresado donde se le pregunte a un usuario por este. Si el mensaje recibido no tiene sentido o no se relaciona con un problema legal que requiera un contrato o es vacio, 'data' contendrá únicamente el texto 'Error'. El mensaje es: '${prompt}'`;

            //console.log(msg);
            sendPromptCRList(msg)
                .then(data => {
                    const jsonData = JSON.parse(data); // Suponiendo que sendPrompt devuelve una cadena JSON como texto
                    if (jsonData && jsonData.data && jsonData.data.title && jsonData.data.desc && jsonData.data.datos && jsonData.data.preguntas) {
                        setResponse({ title: jsonData.data.title, desc: jsonData.data.desc, datos: jsonData.data.datos, preguntas: jsonData.data.preguntas });
                    } else {
                        // Manejo de errores o respuestas inesperadas
                        setResponse({ title: 'Error', desc: ['La respuesta no contiene datos'], datos:['Error'], preguntas: ['error'], respuestas:[] });
                    }
                })
                .catch(error => {
                    console.error("Error al obtener los datos:", error);
                })
                .finally(() => {
                    setLoading(false); // Dejar de cargar cuando la respuesta llega
                });
        } else {
            setResponse({ title: 'Contrato de arrendamiento', desc: ['Descripcion1', 'Descripcion2'],datos:["Dato1","Dato2","Dato3","Dato2","Dato3","Dato2","Dato3","Dato2"],preguntas:["Pregunta1","Pregunta2","Pregunta3"] });
        }
    }, [prompt, prod]);

    return(
    <React.Fragment>
    <SecondaryHeader/>

    <div className="d-flex justify-content-center text-white p-4" style={{height: '90vh'}}>
        <div className="content p-4" style={{position: 'relative', height: '100%', borderRadius: '25px', backgroundColor: '#151515', width: '100%'}}>
                <div class="d-flex justify-content-center">
                    <div class="blob2" style={{paddingRight:'500px'}}></div>
                </div>
                <div className="d-flex flex-column align-items-center">
                        {loading ? (
                            // Mostrar animación de carga
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <div className="spinner-border text-light" role="status">
                                    <span className="sr-only">Cargando...</span>
                                </div>
                                <div className="text-white mb-3" style={{ marginTop: '10px' }}>Estamos buscando el contrato que necesitas</div>
                            </div>

                        ) : (
                            // Mostrar el contenido cuando la respuesta esté lista
                            <>
                                <div className="text-white mb-3">Te recomiendo</div>
                                <ContractRecommendation title={response.title} desc={response.desc} />
                            </>
                        )}
                </div>
        </div>
        </div>

    <Footer/>
    </React.Fragment>
    );
}