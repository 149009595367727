import React, { useState, useEffect } from 'react';
import '../landingPage/Hero.css';

const Question = ({ pregunta, onResponder }) => {
    const [respuesta, setRespuesta] = useState('');

    // Este efecto se ejecutará cada vez que el componente se monte, lo cual sucederá cuando cambie la 'key'
    useEffect(() => {
        setRespuesta(''); // Resetea el estado de respuesta cuando el componente se monta
    }, [pregunta]); // Depende de 'pregunta' para resetear si decides no usar la 'key'

    // Resto del componente...

    return (
        <div className="d-flex align-items-center justify-content-center" style={{minHeight:'100%'}}>
        <form onSubmit={(e) => { e.preventDefault(); onResponder(respuesta); }} className="container  my-5">
        <div class="d-flex justify-content-center">
            <div class="blob2"></div>
        </div>
        <div className="text-center mb-4">
            <h1 className="display-6">{pregunta}</h1>
        </div>
        <div className="mb-3" style={{maxWidth: '600px', margin: 'auto', position: 'relative'}}> 
            <input
                type="text"
                className="form-control"
                value={respuesta}
                onChange={(e) => setRespuesta(e.target.value)}
                placeholder="Escribe aquí..."
                aria-label="Respuesta"
                style={{borderRadius: '20px', height: '3rem', paddingRight: '4rem'}}
            />
            <button className="btn btn-outline-secondary" type="submit" style={{borderRadius: '15px', position: 'absolute', right: '5px', top: '5px', height: 'calc(3rem - 10px', backgroundColor: '#2658f5', color:'var(--blanco)', minWidth:'100px', zIndex:'2'}}>Enviar</button>
        </div>
        </form>
        </div>

    );
};

export default Question;