import React from 'react';
import DraggableField from './DraggableField';

const fieldTypes = [
    { id: 'name', label: 'Nombre' },
    { id: 'signature', label: 'Firma' },
    { id: 'text', label: 'Texto' },
];

export default function Menu() {
    return (
        <div style={{ width: '20%', padding: '10px', borderRight: '1px solid gray' }}>
            <h3>Seleccionar Campo</h3>
            {fieldTypes.map((field) => (
                <DraggableField key={field.id} fieldType={field} />
            ))}
        </div>
    );
}
