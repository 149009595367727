import React from 'react';
import { useDrag } from 'react-dnd';

const DraggableField = ({ fieldType }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'FIELD',
        item: { fieldType },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    return (
        <div
            ref={drag}
            style={{
                padding: '8px',
                margin: '4px',
                backgroundColor: isDragging ? 'lightgreen' : 'white',
                border: '1px solid gray',
                cursor: 'move',
            }}
        >
            {fieldType.label}
        </div>
    );
};

export default DraggableField;
