import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../landingPage/Hero.css';

const Review = ({ preguntasRespuestas, onEditar }) => {

    const navigate = useNavigate();

    const handleClick = () =>{
        navigate("/edit");
    }

    return (
        <div className="container my-5">
        <p className="h2 mb-4">Revisemos tu información antes de generar tu contrato</p>
        <div class="blob2"></div>
        {preguntasRespuestas.map((item, index) => (
            <div key={index} className="row mb-3 bg-dark text-white p-3 align-items-center" style={{ borderRadius: '15px' }}            >
            <div className="col-md-10">
                <p className="mb-1" style={{fontSize: '1rem', color:'#d5d5d5'}}>{item.pregunta}</p>
                <p style={{fontSize: '1.25rem', marginTop:'5px'}}>{item.respuesta}</p>
            </div>
            <div className="col-md-2 d-flex align-items-center justify-content-end">
                <button onClick={() => onEditar(index)} className="btn btn-secondary" style={{borderRadius: '15px', color: 'var(--blanco)', border: 'none'}}>
                Editar
                </button>
            </div>
            </div>
        ))}
        <button className='custom-button' onClick={handleClick}>Enviar</button>
        </div>

    );
};

export default Review;
