import React from 'react';
import '../landingPage/Hero.css';

const Plan = ({ datos, onSiguiente }) => {
    return (
        <div className='row pb-5'>
        <div className="col-md-6 d-flex flex-column" style={{paddingRight: '2.5rem', paddingBottom:'3rem', paddingLeft: '0px', paddingTop:'12rem'}} >
            <div className='blob2'></div>
            <h1 style={{fontSize: '2rem', fontWeight: 'bold'}}>Este es el plan</h1>
            <p style={{fontSize: '1.25rem'}}>Estos son los datos que necesitamos para poder redactar tu contrato. Listos cuando tu lo estés.</p>
        </div>
        <div className="col-md-6 d-flex flex-column justify-content-center h-100" style={{padding: '20px'}}>
            <ul className='list-group bg-dark'>
            {datos.map((dato, index) => (
                <li className='list-group-item' key={index} style={{fontSize: '1rem', backgroundColor: '#151515', color: 'var(--blanco)'}}>{dato}</li>
            ))}
            </ul>
            <button onClick={onSiguiente} style={{borderRadius: '15px', height: 'calc(3rem)', backgroundColor: '#2658f5', color:'var(--blanco)', width:'150px', zIndex:'2', marginTop:'20px', border:'none'}}>
            Comenzar
            </button>
        </div>
        </div>
    );
};

export default Plan;
