import React, { useState, useEffect } from 'react';
import Header from './header';
import SubHeader from './SubHeader';
import HeroSection from './Hero';
import Footer from './Footer';
import Content from './Content';

function LandingPage() {
  const [isMobile, setIsMobile] = useState(false);

  // Función para actualizar el estado basado en el tamaño de la ventana
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Detecta si es móvil (menos de 768px)
    };

    // Agregar el evento de cambio de tamaño
    window.addEventListener('resize', handleResize);

    // Ejecutar la función al cargar para establecer el estado inicial
    handleResize();

    // Limpiar el evento al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (isMobile) {
    return (
      <div style={styles.cover}>
          <i className="fas fa-laptop-code"></i>
          <h1>Opertime se ve mejor en computadora</h1>
          <p>Estamos trabajando para llevar la experiencia a todos los dispositivos.</p>
        </div>
    );
  }

  // Si no es móvil, mostrar el contenido normal
  return (
    <div>
      <Header />
      <HeroSection />
      <Content />
      <Footer />
    </div>
  );
}

const styles = {
  cover: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: 'black',
    textAlign: 'center',
  },
  coverText: {
    color: '#333',
    margin: '10px 0',
  },
};

export default LandingPage;
