import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logos/logo_blanco.svg';

const SecondaryHeader = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark px-4 mt-2">
      <Link className="navbar-brand" to="/">
        <img src={logo} alt="Logo" style={{maxHeight: '40px'}}/>
      </Link>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav ms-auto">
          <li className="nav-item">
            <Link className="nav-link" to="/">Get Help</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/">Exit</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default SecondaryHeader;
