import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../landingPage/header';
import Footer from '../landingPage/Footer';

const CLMLayout = () => {
    return (
        <div>
            <Header />

            <main>
                <Outlet />  {/* Esto es donde React Router renderizará el contenido dinámico */}
            </main>

            <Footer />
        </div>
    );
};

export default CLMLayout;