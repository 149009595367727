const OpenAI = require('openai')
const openai = new OpenAI({apiKey: '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', dangerouslyAllowBrowser: true});

export async function sendPrompt(message) {
  const completion = await openai.chat.completions.create({
    messages: [{ role: "system", content: message }],
    model: "gpt-3.5-turbo",
  });

  return(completion.choices[0].message.content);
}

export async function sendPromptCRList(message) {
  const assistant = await openai.beta.assistants.retrieve(
    "asst_1SnNYyafFIlwo99FGGEZMm4J"
  );

  const thread = await openai.beta.threads.create({
    messages: [
      {
        "role": "user",
        "content": message
      }
    ]
  });

  var run = await openai.beta.threads.runs.create(
    thread.id,
    { 
      assistant_id: assistant.id,
      temperature: 0
    }
  );

  return new Promise((resolve, reject) => {
    const intervalId = setInterval(async () => {
      let runStatus = await openai.beta.threads.runs.retrieve(thread.id, run.id);
      if(runStatus.status === "completed"){
          clearInterval(intervalId);
          let messages = await openai.beta.threads.messages.list(thread.id);
          const contents = messages.data.map(msg => msg.content[0].text.value);
          resolve(contents[0]); // Resuelve la promesa con los contenidos recogidos
      }
    }, 10000);
  }); 
  
}