import React, {useState} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/landingPage/LandingPage';
import CRList from './components/CRList'
import './App.css';
import BuilderPage from './components/ContractBuilder/builder-page';
import Editor from './components/ContractBuilder/editor';
import CLMLayout from './components/digitalContract/CLMLayout';
import FileUpload from './components/digitalContract/FileUpload';
import EditPdf from './components/digitalContract/EditPDF';
import '@fortawesome/fontawesome-free/css/all.min.css';


// Importación de Bootstrap si es necesario
// import 'bootstrap/dist/css/bootstrap.min.css';

export const promptContext = React.createContext();
export const responseContext = React.createContext();
export const contractDataContext = React.createContext();

function App() {
  const [prompt, setPrompt] = useState("");
  const [response, setResponse] = useState({ title: '', desc: [], datos:[], preguntas:[], respuestas:[] });
  const [contractData, setContractData] = useState({title:'', datosForm:[], datosRes:[]});

  return (
    <contractDataContext.Provider value={{contractData,setContractData}}>
      <responseContext.Provider value={{response, setResponse}}>
        <promptContext.Provider value={{prompt, setPrompt}}>
          <Router>
            <Routes>
              <Route path="/" element={<LandingPage/>} exact />
              <Route path="/crlist" element={<CRList />} />

              <Route path="contract" element={<CLMLayout />}>
                    <Route index element={<FileUpload />} />
                    <Route path="edit-pdf" element={<EditPdf />} />
              </Route>

              <Route path="builder" element={<BuilderPage />} />
              <Route path="edit" element={<Editor/>} />
            </Routes>
          </Router>
        </promptContext.Provider>
      </responseContext.Provider>
    </contractDataContext.Provider>
  );
}

export default App;
