// HeroSection.js
import React, {useContext} from 'react';
import {useNavigate } from 'react-router-dom';
import './Hero.css'; 
import { promptContext } from '../../App';

const HeroSection = () => {

    const {setPrompt} = useContext(promptContext);
    const navigate = useNavigate();

    const handleClick = () =>{
        const inputElement = document.getElementById('userInput')
        setPrompt(inputElement.value)

        navigate("/crlist");
    }

    return (
        <div className="hero-section d-flex align-items-center justify-content-center p-4">
            <div className="content rounded-lg" style={{overflow:'hidden'}}>
                <form className='hero-content' onSubmit={(e) => { e.preventDefault(); handleClick(); }}>
                    <div className='blob blob-top-left'></div>
                    <div className='blob blob-bottom-right'></div>
                    <h1 className="hero-title pb-2">Crea el contrato que necesites en minutos con IA</h1>
                    <p className="lead text-left pb-3">Describe que necesitas, te recomendaremos un contrato y después lo personalizas</p>
                    <div className="my-3 w-100 position-relative">
                        <input type="text" id='userInput' className="form-control hero-form position-relative" placeholder="Quiero un contrato para rentar mi casa..." aria-label="Buscar" />
                        <button type="submit" className="btn hero-button position-absolute">Ver Contratos</button> 
                    </div>
                </form>
            </div>
        </div>

    );
};

export default HeroSection;